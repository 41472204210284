<template>
  <div class="main-block finish">
    <div class="main-block-content">
      <div class="main-block-title">Verification</div>
      <div class="grid-column-3">
        <form-group label="Verification status" class="has-value">
          <span v-if="registrationData.verification.isCompleted === true">Account verified</span>
          <span v-else-if="registrationData.verification.isCompleted === null" class="my-error">Verification not started</span>
          <span v-else class="my-error">Incompleted</span>
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">Company info</div>
      <div class="grid-column-3" data-test="company_info">
        <form-group data-test="company_name" label="Company name" :value="registrationData['company-business'].attributes.name" :errors="registrationData['company-business'].errors.name">
          {{ registrationData['company-business'].attributes.name }}
        </form-group>

        <form-group data-test="company_number" label="Company number" :value="registrationData['company-business'].attributes.number" :errors="registrationData['company-business'].errors.number">
          {{ registrationData['company-business'].attributes.number }}
        </form-group>

        <form-group data-test="registration_date" label="Registration Date" :value="registrationData['company-business'].attributes.registered_at" :errors="registrationData['company-business'].errors.registered_at">
          {{ registrationData['company-business'].attributes.registered_at }}
        </form-group>

        <form-group data-test="website" label="Website" v-if="registrationData['company-business'].attributes.website" :value="registrationData['company-business'].attributes.website" :errors="registrationData['company-business'].errors.website">
          <a target="_blank" :href="registrationData['company-business'].attributes.website">
            <span>
              {{ registrationData['company-business'].attributes.website }}
            </span>
          </a>
        </form-group>

        <form-group v-for="file in companyFiles" :value="file" :key="file.name" :label="file.type" data-test="company_file">
          <a target="_blank" :href="file.url" v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group v-if="registrationData['company-business'].errors.files" :errors="registrationData['company-business'].errors.files"></form-group>
      </div>

      <hr>

      <div class="main-block-title">Legal address</div>

      <ul v-if="businessAddressMainErrors.length" class="summary">
        <li class="error" v-for="error in businessAddressMainErrors" :key="error">{{ error }}</li>
      </ul>

      <div class="grid-column-3" data-test="business_address">
        <form-group data-test="business_country" label="Country" :value="getCountryById(businessAddress.country_id)" :errors="businessAddressErrors.country_id">
          {{ getCountryById(businessAddress.country_id) }}
        </form-group>

        <form-group data-test="business_city" label="City" :value="businessAddress.city" :errors="businessAddressErrors.city">
          {{ businessAddress.city }}
        </form-group>

        <form-group data-test="business_street" label="Street" :value="businessAddress.street" :errors="businessAddressErrors.street">
          {{ businessAddress.street }}
        </form-group>

        <form-group data-test="business_house" label="House" :value="businessAddress.house" :errors="businessAddressErrors.house">
          {{ businessAddress.house }}
        </form-group>

        <form-group data-test="business_flat" label="Flat" :value="businessAddress.apartment" :errors="businessAddressErrors.apartment">
          {{ businessAddress.apartment }}
        </form-group>

        <form-group data-test="business_postal_code" label="Postal Code" :value="businessAddress.postal_code" :errors="businessAddressErrors.postal_code">
          {{ businessAddress.postal_code }}
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">Operating address</div>

      <ul v-if="operatingAddressMainErrors.length" class="summary">
        <li class="error" v-for="error in operatingAddressMainErrors" :key="error">{{ error }}</li>
      </ul>

      <div class="grid-column-3" v-if="operatingAddress" data-test="operating_address">
        <form-group data-test="operating_country" label="Country" :value="getCountryById(operatingAddress.country_id)" :errors="operatingAddressErrors.country_id">
          {{ getCountryById(operatingAddress.country_id) }}
        </form-group>

        <form-group data-test="operating_city" label="City" :value="operatingAddress.city" :errors="operatingAddressErrors.city">
          {{ operatingAddress.city }}
        </form-group>

        <form-group data-test="operating_street" label="Street" :value="operatingAddress.street" :errors="operatingAddressErrors.street">
          {{ operatingAddress.street }}
        </form-group>

        <form-group data-test="operating_house" label="House" :value="operatingAddress.house" :errors="operatingAddressErrors.house">
          {{ operatingAddress.house }}
        </form-group>

        <form-group data-test="operating_flat" label="Flat" :value="operatingAddress.apartment" :errors="operatingAddressErrors.apartment">
          {{ operatingAddress.apartment }}
        </form-group>

        <form-group data-test="operating_postal_code" label="Postal Code" :value="operatingAddress.postal_code" :errors="operatingAddressErrors.postal_code">
          {{ operatingAddress.postal_code }}
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">Director</div>

      <ul v-if="directorMainErrors.length" class="summary">
        <li class="error" v-for="error in directorMainErrors" :key="error">{{ error }}</li>
      </ul>

      <div class="grid-column-3" v-if="director" data-test="director">

        <form-group data-test="director_first_name" label="First Name" :value="director.first_name" :errors="directorErrors.first_name">
          {{ director.first_name }}
        </form-group>

        <form-group data-test="director_last_name" label="Last Name" :value="director.last_name" :errors="directorErrors.last_name">
          {{ director.last_name }}
        </form-group>

        <form-group data-test="director_middle_name" label="Middle Name" :value="director.middle_name" v-if="director.middle_name" :errors="directorErrors.middle_name">
          {{ director.middle_name }}
        </form-group>

        <form-group data-test="director_birthdate" label="Birth Date" :value="director.birthday" :errors="directorErrors.birthday">
          {{ director.birthday }}
        </form-group>

        <form-group data-test="director_gender" label="Gender" :value="getGenderById(director.gender)" :errors="directorErrors.gender">
          {{ getGenderById(director.gender) }}
        </form-group>

        <form-group data-test="director_citizenship" label="Citizenship" :value="getCountryById(director.citizenship_country_id)" :errors="directorErrors.citizenship_country_id">
          {{ getCountryById(director.citizenship_country_id) }}
        </form-group>

        <form-group data-test="director_email" label="Email" :value="director.email" :errors="directorErrors.email">
          {{ director.email }}
        </form-group>

        <form-group data-test="director_phone" label="Phone" :value="director.phone" :errors="directorErrors.phone">
          {{ director.phone }}
        </form-group>

        <form-group data-test="director_country" label="Country" :value="getCountryById(director.country_id)" :errors="directorErrors.country_id">
          {{ getCountryById(director.country_id) }}
        </form-group>

        <form-group data-test="director_city" label="City" :value="director.city" :errors="directorErrors.city">
          {{ director.city }}
        </form-group>

        <form-group data-test="director_street" label="Street" :value="director.street" :errors="directorErrors.street">
          {{ director.street }}
        </form-group>

        <form-group data-test="director_house" label="House" :value="director.house" :errors="directorErrors.house">
          {{ director.house }}
        </form-group>

        <form-group data-test="director_flat" label="Flat" v-if="director.apartment" :value="director.apartment" :errors="directorErrors.apartment">
          {{ director.apartment }}
        </form-group>

        <form-group data-test="director_postal_code" label="Postal Code" :value="director.postal_code" :errors="directorErrors.postal_code">
          {{ director.postal_code }}
        </form-group>

        <form-group data-test="director_is_pep" label="Does the Person qualify as a PEP?" :value="getBoolById(director.is_pep)" :errors="directorErrors.is_pep">
          {{ getBoolById(director.is_pep) }}
        </form-group>

        <form-group data-test="director_is_us" label="Does the Person qualify as a U.S. person?" :value="getBoolById(director.is_us)" :errors="directorErrors.is_us">
          {{ getBoolById(director.is_us) }}
        </form-group>

        <form-group data-test="director_files" v-for="file in director.files" :value="file" :label="'Identity Document: ' + getMemberFileTypeByID(file.type)" :key="file.name" :errors="directorErrors.files">
          <a target="_blank" :href="file.url"  v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group data-test="director_address_confirmation_files" v-for="file in director.address_confirmation_files" :value="file" :label="'Address confirmation: ' + getMemberAddressTypeByID(file.type)" :key="file.name" :errors="directorErrors.address_confirmation_files">
          <a target="_blank" :href="file.url"  v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">UBO (Ultimate Beneficial Owner)</div>

      <ul v-if="uboMainErrors.length" class="summary">
        <li class="error" v-for="error in uboMainErrors" :key="error">{{ error }}</li>
      </ul>

      <div v-for="(item, index) in ubo" :key="item.id" data-test="ubo">

        <div class="grid-column-3" :data-test="'ubo-' + index">

          <form-group data-test="ubo-percent" label="Percent" :value="item.percent" :errors="getUboErrorByIndex(index).percent">
            {{ item.percent }}
          </form-group>

          <form-group data-test="ubo-first_name" label="First Name" :value="item.first_name" :errors="getUboErrorByIndex(index).first_name">
            {{ item.first_name }}
          </form-group>

          <form-group data-test="ubo-last_name" label="Last Name" :value="item.last_name" :errors="getUboErrorByIndex(index).last_name">
            {{ item.last_name }}
          </form-group>

          <form-group data-test="ubo-middle_name" label="Middle Name" v-if="item.middle_name" :value="item.middle_name" :errors="getUboErrorByIndex(index).middle_name">
            {{ item.middle_name }}
          </form-group>

          <form-group data-test="ubo-birthdate" label="Birth Date" :value="item.birthday" :errors="getUboErrorByIndex(index).birthday">
            {{ item.birthday }}
          </form-group>

          <form-group data-test="ubo-gender" label="Gender" :value="getGenderById(item.gender)" :errors="getUboErrorByIndex(index).gender">
            {{ getGenderById(item.gender) }}
          </form-group>


          <form-group data-test="ubo_citizenship" label="Citizenship" :value="getCountryById(item.citizenship_country_id)" :errors="getUboErrorByIndex(index).citizenship_country_id">
            {{ getCountryById(item.citizenship_country_id) }}
          </form-group>

          <form-group data-test="ubo-email" label="Email" :value="item.email" :errors="getUboErrorByIndex(index).email">
            {{ item.email }}
          </form-group>

          <form-group data-test="ubo-phone" label="Phone" :value="item.phone" :errors="getUboErrorByIndex(index).phone">
            {{ item.phone }}
          </form-group>

          <form-group data-test="ubo-country" label="Country" :value="getCountryById(item.country_id)" :errors="getUboErrorByIndex(index).country_id">
            {{ getCountryById(item.country_id) }}
          </form-group>

          <form-group data-test="ubo-city" label="City" :value="item.city" :errors="getUboErrorByIndex(index).city">
            {{ item.city }}
          </form-group>

          <form-group data-test="ubo-street" label="Street" :value="item.street" :errors="getUboErrorByIndex(index).street">
            {{ item.street }}
          </form-group>

          <form-group data-test="ubo-house" label="House" :value="item.house" :errors="getUboErrorByIndex(index).house">
            {{ item.house }}
          </form-group>

          <form-group data-test="ubo-flat" label="Flat" v-if="item.apartment" :value="item.apartment" :errors="getUboErrorByIndex(index).apartment">
            {{ item.apartment }}
          </form-group>

          <form-group data-test="ubo-postal_code" label="Postal Code" :value="item.postal_code" :errors="getUboErrorByIndex(index).postal_code">
            {{ item.postal_code }}
          </form-group>

          <form-group data-test="ubo-is_pep" label="Does the Person qualify as a PEP?" :value="getBoolById(item.is_pep)" :errors="getUboErrorByIndex(index).is_pep">
            {{ getBoolById(item.is_pep) }}
          </form-group>

          <form-group data-test="ubo-is_us" label="Does the Person qualify as a U.S. person?" :value="getBoolById(item.is_us)" :errors="getUboErrorByIndex(index).is_us">
            {{ getBoolById(item.is_us) }}
          </form-group>

          <form-group data-test="ubo-files" v-for="file in item.files" :label="'Identity Document: ' + getMemberFileTypeByID(file.type)" :value="file" :key="file.name" :errors="getUboErrorByIndex(index).files">
            <a target="_blank" :href="file.url"  v-if="file.name">
              <icon-attach color="#19191C" class="file-attach"/>
              <span>{{ file.original_name }}</span>
            </a>
          </form-group>

          <form-group data-test="ubo-address_confirmation_files" v-for="file in item.address_confirmation_files" :label="'Address confirmation: ' + getMemberAddressTypeByID(file.type)" :value="file" :key="file.name" :errors="getUboErrorByIndex(index).address_confirmation_files">
            <a target="_blank" :href="file.url"  v-if="file.name">
              <icon-attach color="#19191C" class="file-attach"/>
              <span>{{ file.original_name }}</span>
            </a>
          </form-group>

          <form-group  data-test="ubo-source_funds_files" v-for="file in item.source_funds_files" :label="'Source of funds confirmation: ' + getSourceFundTypeByID(file.type)" :value="file" :key="file.name" :errors="getUboErrorByIndex(index).files">
            <a target="_blank" :href="file.url"  v-if="file.name">
              <icon-attach color="#19191C" class="file-attach"/>
              <span>{{ file.original_name }}</span>
            </a>
          </form-group>

        </div>

        <hr style="margin-bottom: 40px; background-color: #f7f7f7;">
      </div>
      <div v-if="ubo.length === 0" class="custom-error">No UBOs added</div>

      <div class="main-block-title">Business partners</div>

      <ul v-if="partnersMainErrors.length" class="summary">
        <li class="error" v-for="error in partnersMainErrors" :key="error">{{ error }}</li>
      </ul>

      <template v-if="partners.length">
        <div data-test="partners">
          <div v-for="(item, index) in partners" :key="item.id" class="business-partner" :data-test="'partner-' + index">
            <div class="grid-column-3">
              <form-group data-test="partner-company_name" label="Company name" :value="item.name" :errors="getPartnerErrorByIndex(index).name">
                {{ item.name }}
              </form-group>

              <form-group data-test="partner-country" label="Country of registration" :value="getCountryById(item.country_id)" :errors="getPartnerErrorByIndex(index).country_id">
                {{ getCountryById(item.country_id) }}
              </form-group>

              <form-group data-test="partner-type" label="Type" :value="item.type" :errors="getPartnerErrorByIndex(index).type">
                {{ item.type === 'OUTGOING_PAYMENTS' ? 'Outgoing' : 'Incoming'}}
              </form-group>

              <form-group data-test="partner-description" label="Description of the business relationship" :value="item.description" :errors="getPartnerErrorByIndex(index).description">
                <span style="word-break: break-all">
                  {{ item.description }}
                </span>
              </form-group>

              <form-group data-test="partner-files" v-for="file in item.files" :label="getCounterpartyFileTypeById(file.type)" :value="file" :key="file.name" :errors="getPartnerErrorByIndex(index).files">
                <a target="_blank" :href="file.url"  v-if="file.name">
                  <icon-attach color="#19191C" class="file-attach"/>
                  <span>{{ file.original_name }}</span>
                </a>
              </form-group>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="custom-error">No Partners added</div>

      <hr style="margin: 30px 0;">

      <div class="main-block-title">KYC</div>
      <div class="grid-column-3" data-test="kyc">

        <form-group data-test="business_sector" label="Business sector" :value="kyc.sic_codes" :errors="registrationData.kyc.errors.sic_codes">
          <ul>
            <li v-for="item of sicCodes" :key="item.id">
              <span v-if="item.id === 'OTHER'">{{ item.label }}</span>
              <span v-else>{{ item.id }} - {{ item.label }}</span>
            </li>
          </ul>
        </form-group>

        <form-group
            data-test="business_sector_input"
            label="Business sector specification"
            :value="kyc.sic_input"
            :errors="registrationData.kyc.errors.sic_input"
            v-if="kyc.sic_codes.find(i => i.id === 'OTHER')"
        >
          {{ kyc.sic_input }}
        </form-group>

        <form-group data-test="max_single_payment" label="Maximum single payment" :value="kyc.max_single_payment" :errors="registrationData.kyc.errors.max_single_payment">
          {{ kyc.max_single_payment }}
        </form-group>

        <form-group data-test="monthly_turnover" label="Monthly Turnover" :value="getMoneyTurnoverListById(kyc.monthly_turnover)" :errors="registrationData.kyc.errors.monthly_turnover">
          {{ getMoneyTurnoverListById(kyc.monthly_turnover) ? getMoneyTurnoverListById(kyc.monthly_turnover).label : '' }}
        </form-group>

        <form-group data-test="payments_count" label="Number of payments per month" :value="getPaymentsCountListById(kyc.payments_count)" :errors="registrationData.kyc.errors.payments_count">
          {{ getPaymentsCountListById(kyc.payments_count) ? getPaymentsCountListById(kyc.payments_count).label : '' }}
        </form-group>

        <form-group data-test="founds_sources" label="Source of funds" :value="getFoundsSourcesList(kyc.founds_sources)" :errors="registrationData.kyc.errors.founds_sources">
          {{ kyc.founds_sources && kyc.founds_sources.length ? getFoundsSourcesList(kyc.founds_sources) : '' }}
        </form-group>

        <form-group
            data-test="founds_sources_input"
            label="Source of funds input"
            :value="kyc.founds_sources_input"
            :errors="registrationData.kyc.errors.founds_sources_input"
            v-if="kyc.founds_sources_input"
        >
          {{ kyc.founds_sources_input }}
        </form-group>

        <form-group data-test="business_description" label="Business description" :value="kyc.business_description" :errors="registrationData.kyc.errors.business_description">
          {{ kyc.business_description }}
        </form-group>

        <form-group data-test="has_financial_statement" label="Do you have a financial statement?" :value="kyc.has_financial_statement" :errors="registrationData.kyc.errors.has_financial_statement">
          {{ getBoolById(kyc.has_financial_statement) }}
        </form-group>

        <template v-if="kyc.has_financial_statement">
          <form-group data-test="financial_statement_files" label="Financial statement files" :value="kyc.financial_statement_files" :errors="registrationData.kyc.errors.financial_statement_files">
            <ul style="list-style-type: decimal; list-style-position: inside;">
              <li v-for="file in kyc.financial_statement_files" :key="file.name" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                <a :href="file.url" target="_blank">
                  <icon-attach color="#19191C" class="file-attach"/>
                  <span>{{ file.original_name }}</span>
                </a>
              </li>
            </ul>
          </form-group>
        </template>
        <template v-else>
          <form-group data-test="financial_statement_reason" label="Financial statement reason" :value="kyc.financial_statement_reason" :errors="registrationData.kyc.errors.financial_statement_reason">
            {{ kyc.financial_statement_reason }}
          </form-group>
        </template>
      </div>

      <hr>

      <div class="form-buttons-finish">
        <div>

          <label class="form-control-custom" style="margin-bottom: 0; font-size: 12px;">
            <input type="checkbox" v-model="form.is_agree" :value="1" data-test="agree">
            <span class="form-control">I agree with <a :href="links.terms_and_conditions" target="_blank" class="bold underline">Terms & Conditions</a></span>
          </label>

        </div>
        <div>

          <button class="btn-next" @click="submit" :disabled="canSave || loading" data-test="submit">
            <span>Finish</span>
            <icon-next color="#19191C" size="15"/>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import IconNext from "@/assets/icons/IconNext";
import {mapState} from "vuex";
import IconAttach from "@/assets/icons/IconAttach";

export default {
  name: 'Finish',
  components: {
    FormGroup,
    IconNext,
    IconAttach
  },
  data () {
    return {
      form: {
        is_agree: false
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      countries: state => state.init.countries,
      genders: state => state.init.genders,
      boolAnswers: state => state.init.boolAnswers,

      businessSector: state => state.init.kycBusinessSectors,
      moneyTurnoverList: state => state.init.kycMoneyTurnover,
      paymentsCountList: state => state.init.kycPaymentsCount,
      foundsSourcesList: state => state.init.kycFoundSources,

      memberFileTypeList: state => state.init.memberFileTypes,
      addressConfirmationTypeList: state => state.init.addressConfirmationType,
      links: state => state.init.links,
      counterpartyFileTypeList: state => state.init.counterpartyFileType,
      memberFileTypesSourceFunds: state => state.init.memberSourceFundsFileType
    }),

    canSave () {
      return !this.form.is_agree
    },

    companyFiles () {
      return this.registrationData['company-business'].attributes.files
    },

    businessAddress () {
      const el = this.registrationData.address.items.find(i => {
        return i.attributes.type === 'LEGAL'
      })
      return el ? el.attributes : {}
    },
    operatingAddress () {
      const el = this.registrationData.address.items.find(i => {
        return i.attributes.type === 'OPERATING'
      })
      return el ? el.attributes : {}
    },
    businessAddressErrors () {
      // return {}
      const el = this.registrationData.address.items.find(i => {
        return i.attributes.type === 'LEGAL'
      })

      return el && Object.keys(el.errors).length > 0 ? el.errors : {}
    },
    operatingAddressErrors () {
      const el = this.registrationData.address.items.find(i => {
        return i.attributes.type === 'OPERATING'
      })
      return el && Object.keys(el.errors).length > 0 ? el.errors : {}
    },
    businessAddressMainErrors () {
      return typeof this.registrationData.address.errors['LEGAL'] !== 'undefined'
        ? this.registrationData.address.errors['LEGAL']
        : []
    },
    operatingAddressMainErrors () {
      return typeof this.registrationData.address.errors['OPERATING'] !== 'undefined'
          ? this.registrationData.address.errors['OPERATING']
          : []
    },
    director () {
      const el = this.registrationData.member.items.find(i => {
        return i.attributes.type === 'DIRECTOR'
      })
      return el ? el.attributes : {}
    },
    /**
     * @todo проверить ошибки
     * @return {*[]}
     */
    directorErrors () {
      let director = this.registrationData.member.items.find(i => {
        return i.attributes.type === 'DIRECTOR'
      })

      return director && Object.keys(director.errors).length > 0 ? director.errors : {}
    },
    directorMainErrors () {
      return typeof this.registrationData.member.errors['DIRECTOR'] !== 'undefined'
          ? this.registrationData.member.errors['DIRECTOR']
          : []
    },
    ubo () {
      const items = this.registrationData.member.items.filter(i => {
        return i.attributes.type === 'UBO'
      })

      return items ? items.map(i => {
        return i.attributes
      }) : []
    },
    uboMainErrors () {
      return typeof this.registrationData.member.errors['UBO'] !== 'undefined'
          ? this.registrationData.member.errors['UBO']
          : []
    },
    partners () {
      return this.registrationData.counterparty.items.map(i => {
        return i.attributes
      })
    },
    partnersMainErrors () {
      let errors = []

      if (this.registrationData.counterparty.errors['INCOMING_PAYMENTS']) {
        errors.push(...this.registrationData.counterparty.errors['INCOMING_PAYMENTS'])
      }

      if (this.registrationData.counterparty.errors['OUTGOING_PAYMENTS']) {
        errors.push(...this.registrationData.counterparty.errors['OUTGOING_PAYMENTS'])
      }

      return errors
    },
    kyc () {
      return this.registrationData.kyc.attributes
    },
    sicCodes () {
      return this.kyc.sic_codes
    }
  },
  methods: {
    getCounterpartyFileTypeById(id) {
      let item = this.counterpartyFileTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getCountryById(id) {
      let item = this.countries.find(i => i.id === id)
      return item ? item.label : ''
    },
    getGenderById(id) {
      let item = this.genders.find(i => i.id === id)
      return item ? item.label : ''
    },
    getBoolById(id) {
      let item = this.boolAnswers.find(i => i.id === id)
      return item ? item.label : ''
    },
    getMoneyTurnoverListById(id) {
      return this.moneyTurnoverList.find(i => i.id === id)
    },
    getPaymentsCountListById(id) {
      return this.paymentsCountList.find(i => i.id === id)
    },
    getFoundsSourcesList (ids = []) {
      let items = this.foundsSourcesList.filter(i => {
        return ids.includes(i.id)
      })

      if (items) {
        items = items.map(i => i.label)
      }

      return items ? items.join(', ') : ''
    },
    getMemberFileTypeByID (id) {
      let item = this.memberFileTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getSourceFundTypeByID (id) {
      let item = this.memberFileTypesSourceFunds.find(i => i.id === id)
      return item ? item.label : ''
    },
    getMemberAddressTypeByID (id) {
      let item = this.addressConfirmationTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getUboErrorByIndex (index) {
      let items = this.registrationData.member.items.filter(i => {
        return i.attributes.type === 'UBO'
      })

      if (items) {
        items = items.map(i => {
          return i.errors
        })
      }

      return Object.keys(items[index]).length > 0 ? items[index] : {}
    },
    getPartnerErrorByIndex (index) {
      let items = this.registrationData.counterparty.items.map(i => {
        return i.errors
      })

      return items ? items[index] : {}
    },

    submit () {
      this.loading = true
      this.$sdk.registration.finish(this.registrationHash,{agree: true}).then((response) => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$emit('next-step')

          setTimeout(() => {
            this.$store.dispatch('resetRegistration')
          }, 200)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.finish {
  .form-group {
    line-height: 1.65em;
  }

  a {
    color: #19191C;
    vertical-align: middle;
    overflow-wrap: anywhere;

    span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
    &:visited {
      color: #19191C;
    }
  }

  .file-attach {
    margin-right: 5px;
    position: relative;
    bottom: -4px;
    //transform: rotate(-90deg);
  }
}

.custom-error {
  content: 'Not set';
  color: #EA4C17;
  display: inline-block;
  font-size: 13px;
}

.form-group:not(.has-value) {
  &:after {
    .custom-error
  }
}

.business-partner {
  border-bottom: 1px solid #f7f7f7;
  margin: 0 0 40px;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}
</style>
